class Geolocation {
    constructor() {
        this.coords = {};
    }


    getPosition(successCallback, errorCallback) {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (result) => {
                    successCallback({
                        'position' : result
                    })
                },
                (error) => {
                    errorCallback({
                        'errorMessage' : this.handlePositionError(error)
                    })
                }
            );

        }
    }

    getCoords(successCallback, errorCallback) {
        this.getPosition(
            (result) => {
                successCallback({
                    'coords': {
                        'latitude': result.position.coords.latitude,
                        'longitude': result.position.coords.longitude
                    }
                });
            },
            (result) => {
                errorCallback(result);
            }
        );

    }

    handlePositionError(error) {
        var errorText = '';
        switch(error.code) {
            case error.TIMEOUT:
                errorText += 'Timeout';
                break;
            case error.PERMISSION_DENIED:
                errorText += 'Permission denied';
                break;
            case error.POSITION_UNAVAILABLE:
                errorText += 'Position unavailable';
                break;
            case error.UNKNOWN_ERROR:
                errorText += 'Unknown error';
                break;
        }

        return errorText;
    }



}

export default (
    Geolocation
);