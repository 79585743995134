import CustomPin from "../../map/components/_custom-pin";

let geolocationMarker = null;

const renderLocation = (map, coords, callback = () => {}) => {

    // If geolocation pin already exists, remove it
    if(geolocationMarker !== null) {
        geolocationMarker.removePin();
    }

    geolocationMarker = new CustomPin({
        latlng: coords,
        map: map,
        html: '<div class="geolocation-marker"><div class="round"><div></div>',
    });

    callback();
};

export default (
    renderLocation
);