import {mapStyle} from "../components/map/_map-const";
import renderLocation from "../components/map/_location-marker";
import Geolocation from "../map/components/_geolocation";
import GoogleMap from "../map/components/_map";
import CustomPin from "../map/components/_custom-pin";

window.initMap = function () {

    const map = new GoogleMap({
        zoom: 10,
        center: centerMap,
        mapTypeControl: false,
        scrollwheel: false,
        streetViewControl: false,
        styles: mapStyle,
        fullscreenControl: false,
        zoomControl: false,
        mapContainerSelector : '#square',
        address : '',
    });

    fitters.forEach(fitter => {
        new CustomPin({
            latlng: new google.maps.LatLng(fitter.lat, fitter.lng),
            map: map,
            html: document.getElementById('template-store-pin').innerHTML,
            customCenterCoords: {x: 23, y: 52}
        });
    });

    allotments.forEach(allotment => {
        new CustomPin({
            latlng: new google.maps.LatLng(allotment.lat, allotment.lng),
            map: map,
            html: document.getElementById('template-allotment-pin').innerHTML,
            customCenterCoords: {x: 25, y: 57}
        });
    });

    const geolocation = new Geolocation();
    geolocation.getCoords((result) => {
        const coords = new google.maps.LatLng(result.coords.latitude, result.coords.longitude);

        renderLocation(map, coords, () => {
            map.panTo(coords);
        });
    });
    
};